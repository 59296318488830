import Dropdown from '@theme/components/utils/Dropdown'

export default {
  components: {
    Dropdown,
  },
  props: {
    id: String,
    dropdown: {
      type: Boolean,
      default() {
        return this.$themeSettings.components.DisplayVATSwitcher.dropdown
      },
    },
    showTooltip: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      values: {
        on: {
          value: true,
          label: this.$t('DisplayVATSwitcher.on'),
        },
        off: {
          value: false,
          label: this.$t('DisplayVATSwitcher.off'),
        },
      },
    }
  },
  computed: {
    dropdownId() {
      return this.id || 'DisplayVATSwitcher'
    },
    label() {
      return this.$store.state.currency.displayVAT ? this.values.on.label : this.values.off.label
    },
  },
  methods: {
    dropdownMethod() {},
    selectDisplayVATSetting(setting) {
      this.$store.dispatch('currency/CHANGE_DISPLAYED_VAT', setting)
    },
  },
  async mounted() {
    const cookie = this.$cookies.get('X-Inspishop-DisplayVAT')
    if (cookie !== undefined) {
      await this.$store.dispatch('currency/CHANGE_DISPLAYED_VAT', cookie)
    }
  },
}
